<template>
  <div class="content">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="待支付" name="1"></el-tab-pane>
      <el-tab-pane label="已支付" name="25"></el-tab-pane>
    </el-tabs>
    <orderList :state="activeName"></orderList>
  </div>
</template>

<script>
import orderList from "./orderListComponents.vue";
export default {
  components: {
    orderList,
  },
  data() {
    return {
      activeName: "1",
    };
  },
  mounted() {},

  filters: {},
  methods: {
    handleClick() {},
  },
};
</script>

<style lang="less" scoped>
</style>