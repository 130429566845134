<template>
  <div class="content">
    <div style="clear: both"></div>
    <div class="search-bar">
      <el-form
        :inline="true"
        :model="listQuery"
        ref="departmentInfo"
        size="small"
      >
        <el-form-item style="margin-left: 20px">
          <el-input
            v-model.trim="listQuery.orderId"
            placeholder="订单号"
            style="width: 200px"
            maxlength="64"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item style="margin-left: 20px">
          <el-date-picker
            v-model="searchCondition_date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions0"
            @change="searchConditionDateChage"
          >
          </el-date-picker>
        </el-form-item>

        <!-- <el-form-item style="margin-left: 20px">
          <el-select v-model="listQuery.state" placeholder="请选择">
            <el-option
              v-for="item in ordeStateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" size="mini" @click="searchBtn"
            >搜索</el-button
          >
          <el-button size="mini" @click="resetBtn">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      stripe
      :height="tableHeight"
      border
    >
      <el-table-column type="index" label="序号" width="65"> </el-table-column>
      <el-table-column
        prop="orderId"
        label="订单号"
        min-width="300"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="fileName"
        label="文件名称"
        min-width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="fontNum" label="字数(字)" min-width="150">
      </el-table-column>
      <el-table-column label="支付金额（元）" min-width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.exchangePrice / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="170">
      </el-table-column>

      <!-- <el-table-column label="检测进度" min-width="80"> </el-table-column> -->
      <!-- <el-table-column label="重复率" min-width="80"> </el-table-column> -->
      <!-- <el-table-column label="查重报告" min-width="200"> </el-table-column> -->
    </el-table>
    <div class="page-box mt20r">
      <el-pagination
        background
        @current-change="getTableList"
        :current-page.sync="listQuery.pageNum"
        :page-size="listQuery.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getOrderInfoBypage } from "../../../api/order/index";

import { moneyIsNull } from "../../../util/commonFun.js";
export default {
  props: {
    state: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      moneyIsNull,
      listQuery: {
        beginTime: "", //开始时间 yyyy-MM-DD
        endTime: "", //string结束时间 yyyy-MM-DD
        pageNum: 1,
        pageSize: 10,
        state: +this.state, //订单状态  1未付款 2已付款(检测中) 5已完成（已查重） 不传就是查全部
        orderId: "", //订单号
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      searchCondition_date: [], //搜索条件时间
      total: 0,
      tableData: [
        {
          orderId: "wwwwww",
        },
      ],
    };
  },
  mounted() {
    this.getTableList();
    //  this.getDepartmentData();
  },
  watch: {
    state(val) {
      console.log(val, "3333333");
      this.getTableList();
    },
  },
  methods: {
    searchConditionDateChage(val) {
      if (val) {
        this.listQuery.beginTime = val[0];
        this.listQuery.endTime = val[1];
      } else {
        this.listQuery.beginTime = "";
        this.listQuery.endTime = "";
      }
    },
    //列表查询
    getTableList() {
      // this.listQuery.pages = val;
      this.getTableData();
    },
    getTableData() {
      this.listQuery.state = this.state;
      getOrderInfoBypage(this.listQuery).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //搜索按钮
    searchBtn() {
      this.listQuery.pageNum = 1;
      this.getTableList();
    },
    // 重置
    resetBtn() {
      this.searchCondition_date = "";
      this.listQuery.orderId = "";
      this.listQuery.userPhone = "";
      this.listQuery.beginTime = "";
      this.listQuery.endTime = "";
      this.getTableData();
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .search-top {
    height: 40px;
    float: right;
  }

  .search-bar {
    display: flex;
    justify-content: space-between;
  }
  .option-list-box {
    display: flex;
    span {
      cursor: pointer;
    }
  }
  .page-box {
    text-align: right;
  }
  .ml10r {
    margin-left: 10px;
  }
}
</style>